import { mapState, mapGetters, mapActions } from 'vuex'

export default {
    name: 'Login',
    data: () => ({
        showlogin: false,
        password: '',
        username: '',
    }),
    computed: {
        ...mapGetters('Sgs', ['getLoggedIn']),
        ...mapState('Sgs', ['user', 'failLogin', 'loginNotice']),
    },
    methods: {
        ...mapActions('Sgs', ['processLogin']),
        runLogin() {
            this.processLogin({ u: this.username, p: this.password })
                .then(() => {})
                .catch(() => {})
        },
    },
}
